import React from "react";
import { Location } from '@reach/router' // gatsby's dep
import { css, Global } from "@emotion/core";
import { Flex, Container, Styled } from "theme-ui";
import useSiteMetadata from "../hooks/use-sitemetadata";
import theme from "../gatsby-plugin-theme-ui";

const rootCSS = css`
  @media (min-width: 1216px) {
    min-width: 1216px;
  }
`;

const headerCSS = css`
  padding: 0px 0px 0px 0px !important;
  margin: 0px !important;
  border-color: #fff !important;
  overflow: hidden;
  max-height: 420px;
`;

const thumbCSS = css`
  width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
`;

const videoCSS = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
`;

const PageLayout = ({ children }) => {
  const {
    title = "",
    basePath
  } = useSiteMetadata();

  return (
    <Styled.root css={rootCSS}>
      <Global
        styles={{
          body: {
            margin: "0",
            textUnderlinePosition: "under",
            backgroundColor: theme.colors.background
          },
          "*": {
            boxSizing: "border-box"
          }
        }}
      />
      <Flex
        sx={{
          pt: 2,
          display: "flex",
          boxSizing: "border-box",
          minWidth: "346px",
          minHeight: "100vh",
          flexDirection: "column",
          paddingTop: 0,
          backgroundColor: theme.colors.background
        }}
      >
        <Container
          sx={{
            maxWidth: "xl",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            backgroundColor: theme.colors.background,
            padding: [0, 0, 0]
          }}
        >
          <header css={headerCSS}>
            <Location>
              {({ location }) => {
                return (
                  <>
                    {(location.pathname === '/' || location.pathname.includes('/tags/')) && 
                      <div id="picture">
                        <img id="thumbnail" css={thumbCSS} src="/images/kouizine_banner.webp" width="1216" height="399" />
                        <video id="video" css={videoCSS} preload="none" onClick={(e) => {
                            const video = e.target
                            video.paused ? video.play() : video.pause()
                          }}>
                          <source src="/videos/kouizine.mp4" type="video/mp4"/>
                        </video>
                      </div>
                    }
                  </>)
              }}
            </Location>
          </header>
          <Container
            sx={{
              padding: [1, 1, 1],
              paddingTop: [1, 1, 1],
              paddingBottom: [1, 1, 1]
            }}
          >
            <main>{children}</main>
          </Container>
        </Container>
      </Flex>
    </Styled.root>
  );
};

export default PageLayout;
